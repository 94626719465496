import React, { createContext, useState } from 'react'
import  axios from 'axios';
import $ from 'jquery';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const apiContext = createContext(0);

export default function ApiProvider(props) {

  const {t, i18n} = useTranslation();

  // const baseUrl = "http://localhost:8080/api/v1/";

  if (process.env.NODE_ENV === 'production') {
    var baseUrl = "https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/";
    console.log = () => {}
  } else {
    var baseUrl = "http://localhost:8080/api/v1/";
  }

  const [token, setToken] = useState(localStorage.getItem("token"))

              // get data
  async function getData(endPoint="") {
    const res = await axios({
      url : `${baseUrl}${endPoint}`,
      method : "get",
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    console.log(res);
    if (res.data) {
      return res.data;
    }
  }
        // get data open source
  async function fetchData(url) {
    if (url) {
      const res = await axios({
        url ,
        method : "get",
        headers : {
          'Content-Type': 'application/json',
        } ,
      })
      console.log(res);
      if (res.data) {
        return res.data;
      }
    }
  }

  async function sendRequest(url, method, body) {
    if (url) {
      const res = await axios({
        url,
        method,
        body,
        headers : {
          'Content-Type': 'application/json', 
          accept: 'application/json',
        },
      })
      console.log(res);
      if (res.data) {
        return res.data;
      }
    }
  }


          // add data and edit data
  async function responseApi(endPoint="", method, data={}) {
    const res = await axios({
        url : `${baseUrl}${endPoint}`,
        method,
        headers : {
            'Content-Type': 'application/json', 
            "authorization" : `Bearer ${token}`,
        },
        data,
    })
    console.log(res);
    if (res.data) {
      return res.data;
    }
  }

            // remove data
  async function removeUserApi(id, endPoint, setDataRow) {
    const {data} = await axios({
      method : "patch",
      url : `${baseUrl}${endPoint}/${id}`,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      } ,
    })
    props.togglePopUp('add-confirm');
    if (data.message === "success") {
    }else {
    }
  }

  // quick edit in table
  async function quickEditTable(endPoint, dataUser, id) {
    const {data} = await axios({
      url : `${baseUrl}${endPoint}/quickEdit/${id}` ,
      method : "patch",
      data : dataUser,
      headers : {
        'Content-Type': 'application/json', 
        "authorization" : `Bearer ${token}`,
      }
    })
    console.log(data);
    if (data.message === "success") {
    }else {
      return data
    }
  }

  

    return  <apiContext.Provider value= {{getData, fetchData, sendRequest, responseApi, removeUserApi, quickEditTable}}>
                {props.children}
            </apiContext.Provider>
}


export const useApi = ()=> {
  const context = useContext(apiContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserContextProvider.");
  }
  return context;
};