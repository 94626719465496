import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import i18n from './i18n';
import { Route, Routes, useNavigate, useHref, useLocation, useParams, useSearchParams, } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import PaymentRegistration from './components/PaymentRegistration/PaymentRegistration';
import ApiProvider, { useApi } from './components/Store/ApiContext';
import { getIPLocation } from 'react-ip-location';
import hash from 'hash.js';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import cryptoJs from 'crypto-js';
import PricingPlans from './components/PricingPlans/PricingPlans';
import Registration from './components/Registration/Registration';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const {fetchData} = useApi();
  const location = useLocation()
  const [isFailedPayment, setIsFailedPayment] = useState(false);

  console.warn = () => {}

  if (process.env.NODE_ENV === 'production') {
    var baseUrl = "https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/";
    console.log = () => {}
  } else {
    var baseUrl = "http://localhost:8080/api/v1/";
  }

  const navigate = useNavigate();
  const href = useHref();

  const [Language, setLanguage] = useState('');
  const [geoInfo, setGeoInfo] = useState({});

  async function fetchGeoInfo(params) {
    const geoData = await getIPLocation()
    if (geoData) {
      setGeoInfo(geoData)
    }
    // const data = await fetchData("https://ipapi.co/json")
    // if (data) {
    //   // setValue("countryCompany", {value : data.country_name, label : data.country_name})
    //   console.log(data);
    //   setGeoInfo(data)
    //   // changeLanguageDependCountry(data)
    // }
  }

  function handleRoutesAndLocales(params) {
    const lang = sessionStorage.getItem("i18n");
    // if (!location.search) {
    //   if (!lang && href === "/") {
    //     changeLanguageDependCountry();
    //     // navigate(`/${i18n.language}`);
    //   }else if (lang && href === "/") {
    //     // navigate(`/${lang}`);
    //     i18n.changeLanguage(lang);
    //   } else if (lang && href.split("/").length === 2) {
    //     // navigate(`/${lang}`);
    //     i18n.changeLanguage(lang);
    //   } else if (lang && href.split("/").length === 3) {
    //     // navigate(`/${lang}/${href.split("/")[2]}`);
    //     i18n.changeLanguage(lang);
    //   }
    // } else {
    //   if (lang) {
    //     // navigate(`/${lang}/${location.search}`);
    //   } else {
    //     // navigate(`/${i18n.language}/${location.search}`);
    //   }
      
    // }

    if (!location.search) {
      if (!lang) {
        changeLanguageDependCountry();
      }
    } else {
      if (!lang) {
        changeLanguageDependCountry();
      }
    }
  }

  function changeLanguageDependCountry() {
    const arabCountries = ["BH", "EG", "IQ", "JO", "KW", "LB", "LY", "MR", "MA", "OM", "PS", "QA", "SA", "SD", "SY", "TN", "AE", "YE"];
    let language = "";
    arabCountries.forEach(ele => {
      if (ele === geoInfo.country ) {
        language = "ar";
        return 0;
      }
    });
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
    sessionStorage.setItem("i18n", i18n.language);
  }



  useEffect(() => {
    fetchGeoInfo();
    console.log(location);
    handleRoutesAndLocales()
    // getQuery()
  }, []);

  useEffect(() => {
    if (location.pathname !== "/registration" && !localStorage.getItem("registration") && isFailedPayment) {
      setIsFailedPayment(false)
    }
  }, [location.pathname])
  
  return (
    <>
    <div dir={i18n.language === "ar" ? 'rtl' : "ltr"}>
      <div className='main scrollbar-style'>
        <Navbar/>
        <ApiProvider baseUrl={baseUrl}>
        <ToastContainer toastClassName="custom-toast" />
        <Routes>
          {/* <Route path={`/:locales`}> */}
            <Route path='' element={<Home baseUrl={baseUrl} setIsFailedPayment={setIsFailedPayment} isFailedPayment={isFailedPayment} geoInfo={geoInfo} />}/>
            <Route path='registration' element={<Registration baseUrl={baseUrl} fetchGeoInfo={fetchGeoInfo} geoInfo={geoInfo} setIsFailedPayment={setIsFailedPayment} isFailedPayment={isFailedPayment}/>}/>
            <Route path='plans' element={<PricingPlans />}/>
          {/* </Route> */}
          {/* <Route path={`/test`}  element={<Home baseUrl={baseUrl}/>}/> */}
        
        </Routes>
        </ApiProvider>
        <Footer/>

      </div>
    </div>
    
    
    
    
    </>
  );
}

export default App;
