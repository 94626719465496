import { faMoneyBillTrendUp, faHouseLaptop, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import imageWhyUse from '../../img/martial/whyAr.png';
import {Trans, useTranslation} from 'react-i18next';
// import { Link } from 'react-router-dom';


export default function WhyEgarhub() {
    
    const { t, i18n } = useTranslation()

  return (
    <>
    <section className='whyEgarhub my-6'>

        <div className='row'>
            {/* <div className='col-lg-6 mt-5 wow animate__animated animate__fadeInLeft'> */}
            <div className='col-lg-6'>
                <Trans>
                    <div className='mb-5'>
                        <h6 className='mb-3 text-mySecondary'>{t("Master Your Rentals, Boost Your Profits")}</h6>
                        <h2 className='text-myPrimary fw-bolder'>{t("Why Egarhub")}</h2>
                        <p className='mt-4 text-myPrimary'>{t("Why Description")} </p>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <FontAwesomeIcon className='fs-3 mb-3 text-myPrimary' icon={faThumbsUp} />
                            <h5 className='mb-3 title-why fw-bold text-myPrimary'>{t("Ease of Use")}</h5>
                            <p className='paragraph-why'>{t("No training needed.")} <br />{t("Get started today.")}</p>
                        </div>
                        <div className="col-4">
                            <FontAwesomeIcon className='fs-3 mb-3 text-myPrimary' icon={faMoneyBillTrendUp} />
                            <h5 className='mb-3 title-why fw-bold text-myPrimary'>{t("Boosting Profits")}</h5>
                            <p className='paragraph-why'>{t("Boost your bottom line with")} <br /> {t("simple and effective management.")}</p>
                        </div>
                        <div className="col-4">
                            <FontAwesomeIcon className='fs-3 mb-3 text-myPrimary' icon={faHouseLaptop} />
                            <h5 className='mb-3 title-why fw-bold text-myPrimary'>{t("Why: Listing Portal")}</h5>
                            <p className='paragraph-why'>{t("List your properties,")} <br />{t("conquer the market.")}</p>
                        </div>
                    </div>
                    <div className='mt-4'>
                        {/* <Link to={"registration"} className='btn-myPrimary'>{t("Subscribe Now")}</Link> */}
                        <a href="#pricing-plans" className='btn-myPrimary'>{t("Subscribe Now")}</a>
                    </div>
                </Trans>
            </div>
            <div className='col-lg-6 mt-5'>
                <div>
                    <img className='w-100' src={imageWhyUse} alt="why use" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
