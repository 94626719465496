import React, { useEffect, useRef, useState } from 'react';
import decoritionTitle from "../../img/martial/landlord01.svg";
import desktopImageAr from "../../img/martial/aboutAr.png";
import desktopImageEn from "../../img/martial/aboutEn.png";
import Typed from 'typed.js';
import FormRequest from '../FormRequest/FormRequest';
import {Trans, useTranslation} from 'react-i18next';
import { Link, useHref, useLocation, useNavigate } from 'react-router-dom';
import ConfirmSendEmail from '../ConfirmSendEmail/ConfirmSendEmail';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import cryptoJs from 'crypto-js';
import moment from 'moment';
import { useApi } from '../Store/ApiContext';
import $ from 'jquery';

export default function About({setIsFailedPayment}) {

  const href = useHref();
  const location = useLocation()
  const navigate = useNavigate();
  const ele = useRef(null);
  const { t, i18n } = useTranslation();
  const {responseApi} = useApi()

  const [confirmSend, setConfirmSend] = useState(false);

  let typing;
  
  function typed() {
    const types = [t("More Money"), t("Manage"), t("Track")];
    console.log(types);
    typing = new Typed(ele.current, {
      strings: types,
      typeSpeed: 70,
      loop : true,
      loopCount: Infinity,
      showCursor: false,
    });
  }

  function sendConfirmMessage() {
    getQueryStatus()
  }

  function getQueryStatus() {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    if (status === "success") {
      setConfirmSend(true)
    }
    navigate('', { replace: true });    
  }


  function getRegistrationData() {
      const registrationData = JSON.parse(localStorage.getItem("registration"));
      if (registrationData) {
        saveRegistration(registrationData);
        saveTransaction(registrationData);
        sendRegistrationEmail(registrationData);
        setConfirmSend(true);
        localStorage.removeItem("registration");
      }
      
  }

  async function sendRegistrationEmail(registrationData) {
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key" : "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"
      },
      body: JSON.stringify({
        sender: {
          name: "EgarHub",
          email: "noreply@egarhub.com",
        },
        to: [
          {
            email: registrationData.userEmail,
            name: registrationData.firstName + " " + registrationData.lastName,
          },
        ],
        htmlContent:
          "<!DOCTYPE html> <html> <body> <h3>Thank you to be a part of EgarHUB <br/> We will review your account, prepare your HUB, and contact you within the next 48 Hrs. </h3></body></html>",
        subject: "Registration Egarhub",
        replyTo: {
          email: "noreply@egarhub.com",
          name: "EgarHub",
        },
      }),
    };

    const response = await fetch('https://api.brevo.com/v3/smtp/email', options);
    const data = await response.json();
    return data;

  }

  async function saveRegistration(registrationData) {
    // if (data && invoice.numberOfMonths && invoice.numberOfMonths) {
      const payload = {
        orgName: registrationData.orgName,
        orgPhone: registrationData.orgPhone,
        orgCountry: registrationData.orgCountry,
        orgAddress: registrationData.orgAddress,
        orgLang: registrationData.orgLang,
        domainName: registrationData.domainName,
        numberOfUnits: registrationData.numberOfUnits,
        subscriptionStart: registrationData.subscriptionStart,
        subscriptionEnd: registrationData.subscriptionEnd,
        firstName: registrationData.firstName,
        lastName: registrationData.lastName,
        userPhoneNumber: registrationData.userPhoneNumber,
        userEmail: registrationData.userEmail,
        roleName: registrationData.roleName,
      }
      const data = await responseApi("landing/newOrganization", "post", payload);
      console.log(data);
      return data.message
    // }
    
   
  }

  async function saveTransaction(registrationData) {
      const payload = {
        firstName: registrationData.firstName,
        lastName: registrationData.lastName,
        phone: registrationData.userPhoneNumber,
        email: registrationData.userEmail,
        paymentMethod: registrationData.paymentMethod,
        totalPaid: registrationData.totalPaid,
        fees: registrationData.fees,
        exchangeRate: registrationData.exchangeRate,
        currency: registrationData.currency,
        unitSubscriptionPrice: registrationData.unitSubscriptionPrice,
        numberOfUnits: registrationData.numberOfUnits,
      }
      const data = await responseApi("transaction", "post", payload);
      console.log(data);
      return data.message
    
   
  }


  function getQueryHMAC() {
    const queryParams = new URLSearchParams(location.search);

    const hmacQuery = {
      amount_cents :  queryParams.get('amount_cents'),
      created_at :  queryParams.get('created_at'),
      currency :  queryParams.get('currency'),
      error_occured :  queryParams.get('error_occured'),
      has_parent_transaction :  queryParams.get('has_parent_transaction'),
      objId :  queryParams.get('id'),
      integration_id :  queryParams.get('integration_id'),
      is_3d_secure :  queryParams.get('is_3d_secure'),
      is_auth :  queryParams.get('is_auth'),
      is_capture :  queryParams.get('is_capture'),
      is_refunded :  queryParams.get('is_refunded'),
      is_standalone_payment :  queryParams.get('is_standalone_payment'),
      is_voided :  queryParams.get('is_voided'),
      ordeIid :  queryParams.get('order'),
      owner :  queryParams.get('owner'),
      pending :  queryParams.get('pending'),
      source_dataPan :  queryParams.get('source_data.pan'),
      source_dataSub_type :  queryParams.get('source_data.sub_type'),
      source_dataType :  queryParams.get('source_data.type'),
      success :  queryParams.get('success'),
    }
    const HMAC = queryParams.get('hmac');
    navigate('', { replace: true });
    validateHMAC(hmacQuery, HMAC);
    
  }

  function validateHMAC(hmacQuery, HMAC) {

      const myHMAC = hmacQuery.amount_cents + hmacQuery.created_at + hmacQuery.currency + hmacQuery.error_occured + hmacQuery.has_parent_transaction + hmacQuery.objId + hmacQuery.integration_id + hmacQuery.is_3d_secure + hmacQuery.is_auth + hmacQuery.is_capture + hmacQuery.is_refunded + hmacQuery.is_standalone_payment + hmacQuery.is_voided + hmacQuery.ordeIid + hmacQuery.owner + hmacQuery.pending + hmacQuery.source_dataPan + hmacQuery.source_dataSub_type + hmacQuery.source_dataType + hmacQuery.success
      const secretKey = "66EDAFA3CAA44FE06CECECAA944F0CFD";
      const hmacHash = hmacSHA512(myHMAC, secretKey).toString(cryptoJs.enc.Hex).toLowerCase();
      console.log(hmacQuery);
      console.log(hmacHash);
      console.log(HMAC);
      console.log(hmacHash === HMAC)
      if (hmacHash === HMAC && hmacQuery.success === "true" && hmacQuery.pending === "false" ) {
        getRegistrationData();
      } else {
        setIsFailedPayment(true);
        navigate("registration");
      }
  }

  useEffect(() => {
    typed();

    return () => {
      typing.destroy();
    };
  }, [i18n.language]);

  // useEffect(() => {
  //   if (location.search && localStorage.getItem("registration")) {
  //     getQueryHMAC()
  //   }
  // }, [])

  useEffect(() => {
    sendConfirmMessage()
    if ($(".main").scrollTop() !== 0) {        
      $(".main").scrollTop(0);
    }
  }, []);
  
  return (
    <section id='about' className='my-5'>
      <div className='row g-4 flex-lg-row flex-column-reverse'>
        <div className='col-lg-6 about-item-1'>
          <div className='text-lg-start text-center '>
            <Trans>
              <div className='d-flex mb-3 justify-content-lg-start justify-content-center'>
                {/* <img className={i18n.language !== "ar" ? "" : 'rotate-180'} src={decoritionTitle} alt="" /> */}
                
                  <h5 className='px-2 pt-2 fw-semibold text-mySecondary'>{t("Get Your First User Free!")}</h5>
                
                {/* <img src={decoritionTitle} alt="" className={i18n.language === "ar" ? "" : 'rotate-180'} /> */}
              </div>
              <div className='mb-3'>
                  <h2 className='fs-1 text-myPrimary fw-bolder'>{t("Simply")} <br /> <h2 className='fs-1 text-mySecondary fw-bolder typed' ref={ele} ></h2>{t("with Egarhub")}</h2>
              </div>
              <div>
                <p className='my-3'>
                  {t("Master Your Rentals, Boost Your Profits")}
                </p>
              </div>
              <div>
                {confirmSend ? <ConfirmSendEmail/>  : <div className='mt-5'>
                    {/* <Link to={"registration"}  className='btn-myPrimary'>{t("Subscribe Now")}</Link> */}
                    <a href="#pricing-plans" className='btn-myPrimary'>{t("Subscribe Now")}</a>
                </div>}
              </div>
            </Trans>
          </div>
          
        </div>
        <div className='col-lg-6 about-item-2'>
          <div className=''>
            {href === "/ar" ?
            <>
              <img src={desktopImageAr} className='w-100 d-lg-inline-block d-none' alt="" />
              <img src={desktopImageAr} className='w-100 d-inline-block d-lg-none'  alt="" />
            </> :
            <>
              <img src={desktopImageEn} className='w-100 d-lg-inline-block d-none' alt="" />
              <img src={desktopImageEn} className='w-100 d-inline-block d-lg-none'  alt="" />
            </>}
            
          </div>
        </div>
      </div>
    </section>
    
  )
}
