import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select';
import i18n from '../../i18n';
import desktopImageAr from "../../img/martial/aboutAr.png";
import desktopImageEn from "../../img/martial/aboutEn.png";
import axios from 'axios';
import ToggleButton from '../ToggleButton/ToggleButton';
import { useApi } from '../Store/ApiContext';
import moment from 'moment';
import { getIPLocation, checkIPLocation } from 'react-ip-location';
import $ from 'jquery';
import { Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';


export default function Registration({fetchGeoInfo, geoInfo, setIsFailedPayment, isFailedPayment}) {

  const {fetchData, responseApi} = useApi();

  const location = useLocation();
  const navigate = useNavigate();

  const [countryOptions, setCountryOptions] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState("go");
  const [subscriptionPlanRange, setSubscriptionPlanRange] = useState("month");
  const [startPaymentFlow, setStartPaymentFlow] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");

  const [invoice, setInvoice] = useState({
    unitSubscriptionPrice : 5,
    numberOfMonths : subscriptionPlanRange === "month" ? 1 : 12
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    getValues,
    setValue
  } = useForm({
    defaultValues : {
      // numberOfUnits : "1"
    }
  });

  const langOptions = [
    { value: 'ar', label: t("Arabic") },
    { value: 'en', label: t("English") },
  ];  

  const onSubmit = (data) => {
    console.log(data);
    // if (validateRegistrationDataAndStore(data)) {
    //   setStartPaymentFlow(true);
    // }
    sendRegistration(data)
  };
  
  async function fetchCountries() {
    let countries = []
    const data = await fetchData("https://restcountries.com/v3.1/all")
    data?.forEach(ele => {
      countries.push({label : ele.name.common, value : ele.name.common, countryCode : ele.cca2 })
    });
    console.log(countries);
    setCountryOptions(countries)
  }

  async function fetchExchangeRates() {
    const url = "https://openexchangerates.org/api/latest.json?app_id=18d325dfb2354d0e8accbeb7275c1e56&symbols=EGP"
    const {rates} = await fetchData(url)
    console.log(moment().add(1, "hour").format());
    localStorage.setItem("rate", JSON.stringify({EGP : rates.EGP, exp : moment().add(1, "hour").format()}));
    return rates.EGP
  }

  async function validateExchangeRates() {
    const rate = JSON.parse(localStorage.getItem("rate"));
    console.log(rate);
    if (rate) {
      if (rate.exp > moment().format()) {
        console.log(rate.exp > moment().format());
      } else {
        console.log(rate.exp > moment().format());
        fetchExchangeRates();
      }
      const myInvoice = {...invoice}
      myInvoice.exchangeRate = Math.ceil(rate.EGP)
      myInvoice.exchangeRateStored = rate.EGP
      setInvoice(myInvoice);
    } else {
      const exchangeRate = await fetchExchangeRates();
      console.log(exchangeRate);
      const myInvoice = {...invoice}
      myInvoice.exchangeRate = Math.ceil(exchangeRate)
      myInvoice.exchangeRateStored = exchangeRate
      setInvoice(myInvoice)
    }
  }

  async function sendRegistration(data) {
    if (data && subscriptionPlanRange) {
      const payload = {
        orgName: data.orgName,
        orgPhone: data.orgPhone,
        orgCountry: data.orgCountry.value,
        orgAddress: data.orgAddress,
        orgLang: data.orgLang.value,
        domainName: data.domainName,
        numberOfUsers: 1,
        subscriptionStart: moment().startOf('day'),
        subscriptionEnd: moment().add(subscriptionPlanRange === "month" ? 1 : 12 , "months").startOf('day'),
        firstName: data.firstName,
        lastName: data.lastName,
        userPhoneNumber: data.userPhoneNumber,
        userEmail: data.userEmail,
        userPassword: data.userPassword,
        confirmUserPassword: data.confirmUserPassword,
        roleName: data.roleName,
        plan : subscriptionPlan
      }
      const registrationResponse = await responseApi("landing/newOrganization", "post", payload);
      await handleApiResponse(registrationResponse);
    }
  }
  
  async function handleApiResponse(result) {
    console.log(result);
    if (result?.message === "success") {
        navigate("/?status=success");
    } else if (result?.message === "failed to add a new user.") {
        toast.error("يوجد شئ خطأ بكلمة السر", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    } else if (result?.message === "failed to add a new owner.") {
        toast.error(t( "failed to add a new owner."), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    } else if (result?.message === "The email already exists. Please check the data.") {
        toast.error(t("The email already exists. Please check the data."), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    } else if (result?.message === "The subdomain already used. Please check the data.") {
      toast.error(t("The subdomain already used. Please check the data."), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
      });
    } else if (result?.message === "don't exist this plan.") {
      toast.error(t("don't exist this plan."), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
      });
    }
  }

  // function calcTotalPayment() {
  //   const myInvoice = {...invoice};
  //   const numberOfUnits = parseInt(getValues("numberOfUnits"));
  //   const numberOfMonths = subscriptionPlanRange === "month" ? 1 : 10;
  //   if (geoInfo?.country === "EG" && numberOfUnits > 0) {
  //     const registerCost = (numberOfUnits * (myInvoice.exchangeRate * invoice.unitSubscriptionPrice)) * numberOfMonths;
  //     myInvoice.totalPayment = registerCost;
  //     myInvoice.paymentGatewayFees = ( registerCost * paymentGatewayFees.EGP ) + paymentGatewayFees.fixedEGP;
  //   } else {
  //     const registerCost = (numberOfUnits * invoice.unitSubscriptionPrice) * numberOfMonths;
  //     myInvoice.totalPayment = registerCost;
  //     myInvoice.paymentGatewayFees = registerCost * paymentGatewayFees.USD;
  //   }
  //   myInvoice.subscriptionPLan = subscriptionPlanRange;
  //   myInvoice.numberOfMonths = subscriptionPlanRange === "month" ? 1 : 12;
  //   myInvoice.currency = geoInfo?.country === "EG" ? "EGP" : "USD";
  //   // myInvoice.paymentMethod = paymentMethod;
  //   console.log(myInvoice);
  //   setInvoice(myInvoice);
  // }

  function findDefaultCountryCompany(countries) {
    const countryCompany = countries.find((c) => c.countryCode === geoInfo.country)
    console.log(countryCompany);
    setValue("orgCountry", countryCompany)
  }

  // function validateRegistrationDataAndStore(data) {
  //   if (data && invoice.totalPayment) {
  //     const payload = {
  //       orgName: data.orgName,
  //       orgPhone: data.orgPhone,
  //       orgCountry: data.orgCountry.value,
  //       orgAddress: data.orgAddress,
  //       orgLang: data.orgLang.value,
  //       domain : data.domain,
  //       domainName: data.domainName,
  //       numberOfUnits: data.numberOfUnits,
  //       subscriptionStart: moment().startOf('day'),
  //       subscriptionEnd: moment().add(invoice.numberOfMonths,"months").startOf('day'),
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       userPhoneNumber: data.userPhoneNumber,
  //       userEmail: data.userEmail,
  //       roleName: data.roleName,
  //       totalPaid : invoice.totalPayment,
  //       fees : invoice.paymentGatewayFees,
  //       exchangeRate : invoice.exchangeRateStored,
  //       currency : invoice.currency,
  //       unitSubscriptionPrice : invoice.unitSubscriptionPrice,
  //       paymentMethod : paymentMethod,
  //     };
  //     localStorage.setItem("registration", JSON.stringify(payload));
  //     return 1;
  //   }
  // }



  function findOption(options, value) {
    const optionValue = options.find((o) => o.value === value );
    console.log(options ,optionValue);
    return optionValue;
  }

  function saveQueryParams() {
    const queryParams = new URLSearchParams(location.search);
    setSubscriptionPlan(queryParams.get('plan'))
    setSubscriptionPlanRange(queryParams.get('subscriptionPlanRange'))
    // navigate('', { replace: true });
  }

  // function reSetData() {
  //   const registrationData = JSON.parse(localStorage.getItem("registration"));
  //   setValue("firstName", registrationData.firstName);
  //   setValue("lastName", registrationData.lastName);
  //   setValue("userPhoneNumber", registrationData.userPhoneNumber);
  //   setValue("userEmail", registrationData.userEmail);
  //   setValue("roleName", registrationData.roleName);
  //   setValue("orgName", registrationData.orgName);
  //   setValue("orgPhone", registrationData.orgPhone);
  //   setValue("orgCountry", {label : registrationData.orgCountry, value : registrationData.orgCountry});
  //   setValue("orgAddress", registrationData.orgAddress);
  //   setValue("orgLang", findOption(langOptions, registrationData.orgLang));
  //   setValue("domain", registrationData.domain);
  //   setValue("domainName", registrationData.domainName);
  //   setValue("numberOfUnits", registrationData.numberOfUnits)
  //   setSubscriptionPlanRange(moment(registrationData.subscriptionEnd).diff(moment(registrationData.subscriptionStart), 'months') === 1  ? "month" : "year");
  // }

  useEffect(() => {
    // validateExchangeRates();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (countryOptions.length && geoInfo.ip && !getValues("orgCountry")) {
      findDefaultCountryCompany(countryOptions);
    }
  }, [countryOptions, geoInfo]);

  // useEffect(() => {
  //   console.log(invoice.rate);
  //   if (invoice.exchangeRate && geoInfo?.country) {
  //     calcTotalPayment()
  //   }
  // }, [invoice.exchangeRate, geoInfo?.country, subscriptionPlanRange, watch("numberOfUnits")])
  
  useEffect(() => {
    saveQueryParams()
    if ($(".main").scrollTop() !== 0) {        
      $(".main").scrollTop(0);
    }
  }, []);
  
  // useEffect(() => {
  //   if (isFailedPayment && localStorage.getItem("registration")) {
  //     reSetData();
  //     localStorage.removeItem("registration");
  //   }
  // }, [isFailedPayment])


  
  
  

  return (
    <>
    <section className='payment-registration'>
      <div className='container'>
        <div className='row gx-4'>
        <div className='col-md-4 d-none d-md-flex'>
          <div className='registration-image d-flex align-items-center'>
            <div className='image-item '>
              {i18n.language === "en" ? <img src={desktopImageAr} className='w-100' alt="" /> : <img src={desktopImageEn} className='w-100' alt="" />}
            </div>
          </div>
        </div>
        <div className='col-md-8'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ul className='row g-2'>
                <div>
                  {isFailedPayment ? <Alert variant={"danger"}>
                  {t("Can't accept the payment")}
                  </Alert> : <></>}
                  
                </div>
                <div className='mb-2' >
                  <h5 className='title-info'>{t("Contact Information")}</h5>
                </div>
                <li className='registration-input col-md-6'>
                    <input
                      id="firstName"
                      placeholder={t('First Name')}
                      {...register("firstName", { required: true, pattern : "^[a-zA-Z]+$" })}
                    />
                    {errors.firstName && errors.firstName.type === "required" && (
                      <span className='error-message text-danger'>{t("Please enter the first name")}</span>
                    )}            
                </li>
                <li className='registration-input col-md-6'>
                  <input
                    id="lastName"
                    placeholder={t('Last Name')}
                    {...register("lastName", { required: true, pattern : "^[a-zA-Z]+$" })}
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <span className='error-message text-danger'>{t("Please enter the last name")}</span>
                  )}
                </li>
                <li className='registration-tel col-md-6'>
                  <div className='registration-input-tel'  dir='ltr'>
                    <Controller
                    name="userPhoneNumber"
                    control={control}
                    rules={{
                      required: t("Please enter the phone number with the country code"),
                      maxLength: {
                        value: 20,
                        message : t("Please enter the phone number with the country code")
                      },
                      minLength: {
                        value: 4,
                        message : t("Please enter the phone number with the country code")
                      }
                    }}
                    render={({ field: { ref, ...field }}) =><PhoneInput
                      {...field}
                      value={field.value}
                      onChange={field.onChange}
                      innerRef={ref}
                      searchPlaceholder={t("search")}
                      enableSearch={true}
                      disableSearchIcon={true}
                      country={isFailedPayment ? "" : geoInfo?.country?.toLowerCase()}
                      autoFormat={window.innerWidth > 480 ? true : false}
                      placeholder={t("Phone")}
                      containerClass='text-cairo'
                      searchClass='text-center'
                      searchStyle={{direction : t("dir"), margin : "0"}}
                      dropdownClass='text-left'
                      dropdownStyle={{borderRadius : "10px"}}
                      inputProps={{
                        name: 'phone',
                        dir : "ltr",
                      }}
                      />
                    }
                  />
                  </div>
                  {errors?.userPhoneNumber?.message && (
                    <span className='error-message text-danger'>{errors?.userPhoneNumber?.message}</span>
                  )}

                </li>
                <li className='registration-input col-md-6'>
                  <input
                    id="userEmail"
                    placeholder={t('Email')}
                    {...register("userEmail", { required: true, pattern : "/^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/g", maxLength : 100 })}
                  />
                  {errors.userEmail && errors.userEmail.type === "required" && (
                    <span className='error-message text-danger'>{t("Please enter the email")}</span>
                  )}
                  {errors.userEmail && ( errors.userEmail.type === "pattern" || errors.userEmail.type === "maxLength" ) && (
                    <span className='error-message text-danger'>{t("Invalid email address.")}</span>
                  )}
                </li>
                <li className='registration-input col-md-6'>
                    <input
                      id="roleName"
                      placeholder={t("Role")}
                      {...register("roleName", { required: true })}
                    />
                    {errors.roleName && errors.roleName.type === "required" && (
                      <span className='error-message text-danger'>{t("Please enter the role name")}</span>
                    )}            
                </li>
                <div className='mt-4 mb-2' >
                  <h5 className='title-info'>{t("Company Information")}</h5>
                </div>
                <li className='registration-input col-md-6'>
                  <input
                    id="orgName"
                    placeholder={t("Company Name")}
                    {...register("orgName", { required: true })}
                  />
                  {errors.orgName && errors.orgName.type === "required" && (
                    <span className='error-message text-danger'>{t("Please enter the company name")}</span>
                  )}
                </li>
                <li className='registration-tel col-md-6'>
                  <div className='registration-input-tel' dir='ltr'>
                  <Controller
                  name="orgPhone"
                  control={control}
                  rules={{
                    required: t("Please enter the company Phone number with the country code"),
                    maxLength: {
                      value: 20,
                      message : t("Please enter the company Phone number with the country code")
                    },
                    minLength: {
                      value: 4,
                      message : t("Please enter the company Phone number with the country code")
                    }
                  }}
                  render={({ field: { ref, ...field }}) =><PhoneInput
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    innerRef={ref}
                    searchPlaceholder={t("search")}
                    enableSearch={true}
                    disableSearchIcon={true}
                    country={isFailedPayment ? "" : geoInfo?.country?.toLowerCase()}
                    autoFormat={window.innerWidth > 480 ? true : false}
                    placeholder={t("companyPhone")}
                    containerClass='text-cairo'
                    searchClass='text-center'
                    searchStyle={{direction : t("dir"), margin : "0"}}
                    dropdownClass='text-left'
                    dropdownStyle={{borderRadius : "10px"}}
                    inputProps={{
                      name: 'orgPhone',
                      dir : "ltr",
                    }}
                    />
                  }
                />
                  </div>
                  {errors?.orgPhone?.message && (
                    <span className='error-message text-danger'>{errors?.orgPhone?.message}</span>
                  )}
                </li>
                <li className='registration-input col-md-6'>
                  <Controller
                    name="orgCountry"
                    control={control}
                    rules={{
                      required: t("Please enter country company"),
                    }}
                    render={({ field: { ref, ...field }}) =><Select
                    {...field}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={t("Country")}
                    isClearable={true}
                    isRtl={i18n.language === "ar" ? true : false}
                    name="orgCountry"
                    options={countryOptions}
                  />
                  }
                  />
                  {errors?.orgCountry?.message && (
                    <div className='error-message text-danger'>{errors?.orgCountry?.message}</div>
                  )}
                </li>
                <li className='registration-input col-md-6'>
                  <input
                    id="orgAddress"
                    placeholder={t("Company Address")}
                    {...register("orgAddress", { required: true, minLength : 5, maxLength : 50 })}
                  />
                  {errors.orgAddress && errors.orgAddress.type === "required" && (
                    <span className='error-message text-danger'>{t("Please enter the company's address in detail")}</span>
                  )}
                  {errors.orgAddress && (errors.orgAddress.type === "minLength" || errors.orgAddress.type === "maxLength") && (
                    <span className='error-message text-danger'>{t("Address should not be more than 50 characters.")}</span>
                  )}
                </li>
                <li className='registration-input col-md-6'>
                  <Controller
                    name="orgLang"
                    control={control}
                    rules={{
                      required: t("Please enter main language"),
                    }}
                    render={({ field: { ref, ...field }}) =><Select
                    {...field}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={t("Main Language")}
                    isClearable={true}
                    isRtl={i18n.language === "ar" ? true : false}
                    name="orgLang"
                    options={langOptions}
                  />
                  }
                  />
                  {errors?.orgLang?.message && (
                    <div className='error-message text-danger'>{errors?.orgLang?.message}</div>
                  )}
                </li>
                <div className='mt-4 mb-2' >
                  <h5 className='title-info'><label htmlFor="subDomainName">{t("Listing URL (xxx.egarhub.com)")}</label></h5>
                </div>
                <li className='registration-input col-md-12'>
                  <div className='d-flex registration-input-domain' dir='ltr'>
                    <input
                      id="subDomainName"
                      placeholder={t("Your Listing URL")}
                      {...register("domainName", { required: true, pattern : /^[a-zA-Z0-9||-]+$/ })}
                    />
                    <h5 className='my-auto egarhub-domain'>.egarhub.com</h5>
                  </div>
                  
                  {errors.domainName && (errors.domainName?.type === "required" ) && (
                    <span className='error-message text-danger'>{t("Please enter the subdomain name right")}</span>
                  )}
                  {errors.domainName && errors.domainName?.type === "pattern" && (
                    <span className='error-message text-danger'>{t("The subdomain name cannot contain any symbols other than (-).")}</span>
                  )}
                </li>
                <div className='mt-4 mb-2' >
                  <h5 className='title-info'><label htmlFor="subDomainName">{t("Password of your main account")}</label></h5>
                </div>
                <li className='registration-input col-md-6'>
                  <input
                    id="userPassword"
                    type="password" 
                    placeholder={t("Password")}
                    {...register("userPassword", { required: true, pattern : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ })}
                  />
                  {errors.userPassword && (errors.userPassword.type === "required" || (errors.userPassword.type === "pattern")) && (
                    <span className='error-message text-danger'>{t("Password must be at least 8 characters long and include at least one letter, one number, and one special character.")}</span>
                  )}
                </li>
                <li class="registration-input col-md-6">
                  <input className='primary-input' 
                      type="password" 
                      placeholder={t("Confirm Password")}
                      {...register("confirmUserPassword", { required: true })}
                  />
                  {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                  <div className='text-danger'>{t("Confirm password required")}</div>
                  )}
                  {!errors.userPassword && watch("userPassword") !== watch("confirmUserPassword") && (
                  <div className='text-danger'>{t("password does not match")}</div>
                  )}
                </li>
              </ul>
              <div className='text-center pt-3'>
                <button className='registration-btn' type="submit" >{t("subscribe now")}</button>
              </div>
            </form>
          </div>      
        </div>
      </div>
    </section>
    
    </>
  )
}
