import { Link, redirect, useHref, useNavigate } from 'react-router-dom';
import flagUnitedStates from '../../img/Flag_of_USA.png';
import flagSaudiArabia from '../../img/Flag_of_Saudi_Arabia.png';
import logo from '../../img/logoPrimary.png';
import { Trans, useTranslation } from 'react-i18next';


export default function Navbar() {

  const href = useHref()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  function changeLanguage(language) {
    sessionStorage.setItem("i18n", language);
    window.location.reload()

    // console.log(href);
    // i18n.changeLanguage(language);
    // if (href.split("/").length === 3) {
    //   navigate(`/${i18n.language}/${href.split("/")[2]}`);
    // } else if (href.split("/").length === 2 ) {
    //   navigate(`/${i18n.language}`);
    // } 
  }

  return (
    <>
    <nav className='container'>
      <div className='d-flex justify-content-between  my-4'>
        <Link to={`/`}>
          <div className='logo-width'>
            <img className='w-100' src={logo} alt="" />
          </div>
        </Link>
        <div>
        <img className='flag-img ' src={i18n.language === "ar" ? flagUnitedStates : flagSaudiArabia } onClick={() => i18n.language === "ar" ? changeLanguage("en") :  changeLanguage("ar")} alt="" />
        </div>
      </div>
    </nav>
    </>
  )
}
