import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faCircleCheck, faExchange } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import ToggleButton from '../ToggleButton/ToggleButton'
import { Tab, Tabs } from 'react-bootstrap'

export default function PricingPlans({geoInfo}) {

    const [isExpandTablePlans, setIsExpandTablePlans] = useState(false);
    const [subscriptionPlanRange, setSubscriptionPlanRange] = useState("month");
    const [currency, setCurrency] = useState("EGP");
    const [planPrice, setPlanPrice] = useState({
        "EGP" : 750, // price EGP
        "USD" : 15, // price USD
    });
    const [userPrice, setuserPrice] = useState({
        "EGP" : 500, // price EGP
        "USD" : 10, // price USD
    })

    const tablePlans = [
        { "label": "users", "go": <>{t("1st User for Free")} <br/> {subscriptionPlanRange === "month" ? userPrice[currency] : userPrice[currency] * 10} {t(currency)}  / {t("User")}</>, "pro" :  <>{t("1st User for Free")} <br/> {subscriptionPlanRange === "month" ? userPrice[currency] : userPrice[currency] * 10} {t(currency)}  / {t("User")}</> },
        { "label": "units", "go": "Unlimited", "pro": "Unlimited" },
        { "label": "listingWebsiteWithPaymentGateways", "go": true, "pro": true },
        { "label": "requestFees", "go" : "10% / " + t("Request"), "pro" :  "5% / " + t("Request") },
        { "label": "dashboard", "go": true, "pro": true },
        { "label": "multilanguage (English - Arabic)", "go": true, "pro": true },
        { "label": "onboarding&Training", "go": "Limited", "pro": true },
        { "label": "support", "go": "Limited", "pro": "Priority" },
        { "label": "usersManagement", "go": true, "pro": true },
        { "label": "unitsManagement", "go": true, "pro": true },
        { "label": "ownersManagement", "go": true, "pro": true },
        { "label": "customersManagement", "go": true, "pro": true },
        { "label": "rentingRequestsManagement", "go": true, "pro": true },
        { "label": "indirectRentingRequests", "go": false, "pro": true },
        { "label": "automatedRequestReminders", "go": false, "pro": true },
        { "label": "rentingTimelineTracker", "go": false, "pro": true },
        { "label": "costsManagement", "go": false, "pro": true },
        { "label": "maintenanceTicketsManagement", "go": false, "pro": true },
        { "label": "tasksManagement", "go": false, "pro": true },
        { "label": "realTimeReporting", "go": true, "pro": true },
        { "label": "incomeAndExpensesTracking", "go": false, "pro": true },
        { "label": "financialStatementReport", "go": false, "pro": true }
    ]

    useEffect(() => {
        console.log(geoInfo);
        if (geoInfo?.country && geoInfo?.country !== "EG") {
            console.log("yes");
            setCurrency("USD");
        }
    }, [geoInfo]);

  return (
    <>
    <section id='pricing-plans' className='container pricing-plans'>
        <div className='d-flex justify-content-center'>
            <ToggleButton subscriptionPlan={subscriptionPlanRange} setSubscriptionPlan={setSubscriptionPlanRange}/>
        </div>

        <div className="table-list">
            <ul className="responsive-table">
                <li className="table-header">
                    <div className="col col-1 align-self-start section-details">
                        <div className='align-self-start'>
                            <h2 className='text-myPrimary fw-bolder'>{t("PLANS & PRICING")}</h2>
                            <p>{t("Streamline and grow your portfolio with powerful automation and professional property management and accounting tools designed for landlords.")}</p>
                        </div>
                    </div>
                    <div className="col col-2 align-self-start">
                        <div className='table-header-card-go'>
                            <h6 className='title-table-header fs-1'>GO</h6>
                            <h3 className='fw-bolder'>{t("FREE")}</h3>
                            <div className='text-center py-3'>
                                <Link to={`registration?plan=go&subscriptionPlanRange=${subscriptionPlanRange}`}>
                                    <button className='btn-myPrimary button-card'>{t("Get started for free")}</button>
                                </Link>
                            </div>
                            {/* <p className='desc-table-header pe-1'>{t("Everything you need to replace your spreadsheet and more. Unlock unlimited free units when you enable online rent collection.")}</p> */}
                            <div className='d-md-none mt-3'>
                                {tablePlans?.map((ele, i) => <>
                                    <li className="table-row" key={i} >
                                    <div className="col col-1" data-label="details">
                                        {t(ele.label)}
                                    </div>
                                    <div className="col col-2" data-label="let's talk">
                                        {ele.go === true ? <FontAwesomeIcon className='price-icon' icon={faCircleCheck} /> : ele.go === false ? <FontAwesomeIcon className='price-icon' icon={faCircleXmark} /> : typeof(ele.go) === "string" ? t(ele.go) : ele.go }
                                    </div>
                                </li></> )}
                            </div>


                        </div>
                    </div>
                    <div className="col col-3">
                        <div className='table-header-card-pro'>
                            <p className='header-card-pro'><span>30 </span>{t("DAY FREE TRIAL")} </p>
                            <div className='body-card-pro'>
                                <h6 className='title-table-header fs-1'>PRO</h6>
                                <h3 className='fw-bolder'>{subscriptionPlanRange === "month" ? planPrice[currency] : planPrice[currency] * 10 } <span>{t(currency)}</span></h3>
                                <div className='text-center py-3'>
                                    <Link to={`registration?plan=pro&subscriptionPlanRange=${subscriptionPlanRange}`}>
                                        <button className='btn-myPrimary button-card'>{t("Get started for free")}</button>
                                    </Link>
                                </div>
                                {/* <p className='desc-table-header pe-1'>{t("Powerful automation that scales with you. From finding and managing tenants, to accounting and filing taxes. All the tools you need in one place.")}</p> */}
                                <div className='d-md-none mt-3'>
                                    {tablePlans?.map((ele, i) => <>
                                        <div className="table-row" key={i} >
                                        <div className="col col-1" data-label="details">
                                            {t(ele.label)}
                                        </div>
                                        <div className="col col-3 h-100" data-label="pro">
                                        {ele.pro === true ? <FontAwesomeIcon className='price-icon' icon={faCircleCheck} /> : ele.pro === false ? <FontAwesomeIcon className='price-icon' icon={faCircleXmark} /> : typeof(ele.pro) === "string" ? t(ele.pro) : ele.pro }
                                        </div>
                                    </div></> )}
                                </div>
                            </div>
                        </div>
                    </div>

                </li>
                <div className='d-md-block d-none'>
                    {tablePlans?.slice(0,isExpandTablePlans ? 25 : 13).map((ele, i) => <>
                        <li className="table-row" key={i} >
                        <div className="col col-1" data-label="details">
                            {t(ele.label)}
                        </div>
                        <div className="col col-2" data-label="let's talk">
                            {ele.go === true ? <FontAwesomeIcon className='price-icon' icon={faCircleCheck} /> : ele.go === false ? <FontAwesomeIcon className='price-icon' icon={faCircleXmark} /> : typeof(ele.go) === "string" ? t(ele.go) : ele.go }
                        </div>
                        <div className="col col-3 h-100" data-label="pro">
                        {ele.pro === true ? <FontAwesomeIcon className='price-icon' icon={faCircleCheck} /> : ele.pro === false ? <FontAwesomeIcon className='price-icon' icon={faCircleXmark} /> : typeof(ele.pro) === "string" ? t(ele.pro) : ele.pro }
                        </div>
                    </li></> )}
                </div>
            </ul>
        </div>
        {!isExpandTablePlans ? <div className='text-center d-md-block d-none'>
            <button className='btn-transparent' onClick={() => setIsExpandTablePlans(prev => !prev)}>{t("View all features")}...</button>
        </div> : <></>}
    </section>
    </>
  )
}
