import React, { useEffect, useState } from 'react'
import About from '../About/About';
import Features from '../Features/Features';
import WhyEgarhub from '../whyEgarhub/WhyEgarhub';
import RequestDemo from '../RequestDemo/RequestDemo';
import WOW from 'wow.js';
import { useTranslation, Trans } from "react-i18next";
import ConfirmSendEmail from '../ConfirmSendEmail/ConfirmSendEmail';
import PricingPlans from '../PricingPlans/PricingPlans';

export default function Home({baseUrl, setIsFailedPayment, isFailedPayment, geoInfo}) {
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
    new WOW().init();
  }, [])
  
  return (
    <>
      <div className='container'>
        <About baseUrl={baseUrl} setIsFailedPayment={setIsFailedPayment} isFailedPayment={isFailedPayment}/>
        <Features/>
        <WhyEgarhub/>
        <PricingPlans geoInfo={geoInfo} />
      </div>
      {/* <RequestDemo setConfirmSend={setConfirmSend} baseUrl={baseUrl}/> */}    
    </>
  )
}
