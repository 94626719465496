import { t } from 'i18next';
import $ from 'jquery';

export default function PolicyPopup({togglePopup}) {

  
    function closePopup(e, className) {
        if (e.target.classList.contains("containerBox")) {
            $(`.${className}`).hide()
        }    
    }

    return (<>
        <div className='policy-popup' style={{display : "none"}}>
            <div  className='containerBox ' onClick={(e) => {closePopup(e, "policy-popup")} }>
                <div className='lightContainer scrollbar-style' >
                    <div className='popup-container'>
                        <div>
                            <h5 className='fw-bold'>{t("Refund Policy popup")}</h5>
                            <p className='fs-6 mb-1 mb-3'>{t("We understand that circumstances may change, and you may need to cancel your subscription. Here is our refund policy for subscription purchases:")}</p>
                            <ul className='ps-4'>
                                <li className='fs-6 mb-2'>
                                    <span className='fw-bold'>{t("Cancellation and Refunds:")}</span> {t("You may cancel your subscription at any time. However, we do not offer refunds for partial subscription periods that have already been billed.")}
                                </li>
                                <li className='fs-6 mb-2'>
                                    <span className='fw-bold'>{t("Refund Eligibility:")}</span> {t("Refunds are only available for the initial purchase of a subscription. Renewals or subsequent billing cycles are non-refundable.")}
                                </li>
                                <li className='fs-6 mb-2'>
                                    <span className='fw-bold'>{t("Cancellation Process:")}</span> {t("To cancel your subscription and request a refund for your initial purchase, please contact our support team at support@egarhub.com. Refund requests must be made within 5 days of the initial purchase date.")}
                                </li>
                                <li className='fs-6 mb-2'>
                                    <span className='fw-bold'>{t("Refund Amount:")}</span> {t("Refunds will be issued for the initial subscription purchase amount only.")}
                                </li>
                                <li className='fs-6 mb-2'>
                                    <span className='fw-bold'>{t("Exception")}:</span> {t("If there are technical issues preventing you from accessing the subscription content or using the service, please contact us immediately for assistance. In such cases, we may provide a prorated refund at our discretion.")}
                                </li>
                            </ul>
                            <p><span className='fw-bold'>{t("Contact Us:")}</span> {t("If you have any questions or concerns about our subscription refund policy, please don't hesitate to contact us at support@egarhub.com.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
        
    )
}
