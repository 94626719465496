import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export default function ConfirmSendEmail() {

    const {i18n, t} = useTranslation();

  return (
    <>
    <Trans>
        <div className='parent-thanks animate__animated animate__jackInTheBox animate__slow d-flex justify-content-center align-items-center'>
            <div className='text-center'>
            {/* <FontAwesomeIcon className='text-mySecondary fs-1 mb-3' icon={faCircleCheck} /> */}
                 {/* <h2 className='text-mySecondary fs-1 fw-semibold'>{t("Thank you for your interest!")}</h2> */}
                {/* <p className='text-myDarkPrimary'>{t("We have sent you all the details, please check your email.")}</p> */}
                <h2 className='text-mySecondary fs-2 fw-semibold' dir='ltr'>{t("Thank you to be a part of EgarHub!")}</h2>
                <p className='text-myDarkPrimary'>{t("We have sent you all the details, please check your email.")}</p>
                {/* <p className='text-myDarkPrimary'>Thank you to be a part of EgarHUB We have sent you all the details, please check your email.</p> */}
            </div>
        </div>
    </Trans>
    
    </>
  )
}
