import React from 'react'
import {  Link } from "react-router-dom";
import logoNav from '../../img/LogoSmallColored-nav.png';
import { useTranslation, Trans } from 'react-i18next';
import PolicyPopup from '../PolicyPopup/PolicyPopup';
import $ from 'jquery';
import { clarity } from 'react-microsoft-clarity';

export default function Footer() {

    if (process.env.NODE_ENV === 'production') clarity.init('mdv0n86rlc');

    const { t, i18n } = useTranslation();

    function openPopup(e,className) {
        $(`.${className}`).show();
    }

  return (
    <>
    <footer className='container mt-4'>
        <div className="d-md-flex justify-content-between align-items-center p-3">
            <div className='d-flex'>
                <p className='mb-2'>{t("Contact Us: info@egarhub.com")}</p>
                <p className='mb-2 ms-4 cursor-pointer' onClick={(e) => {openPopup(e, "policy-popup")} }>{t("Refund Policy")}</p>
            </div>
            <div>
                <div className='d-flex align-items-center'>
                    <p>{t("all rights are save.")} ©</p>
                    <Link to={`/${i18n.language}`}>
                        <div>
                            <img className='p-2 pe-0 logo-footer' src={logoNav} alt='egarhub'/>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </footer>
    <PolicyPopup/>
    </>
  )
}
