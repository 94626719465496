import React from 'react'
import featuresAr from '../../img/martial/featuresAr.png';
import featuresEn from '../../img/martial/featuresEn.png';
// import arrow1 from '../../img/martial/landlord04.svg';
// import arrow2 from '../../img/martial/landlord05.svg';
import featureFront1 from '../../img/martial/dashboard-128px.png';
import featureFront2 from '../../img/martial/tracking-128px.png';
import featureFront3 from '../../img/martial/reports-128px.png';
import featureFront4 from '../../img/martial/listing-128px.png';
import featureBack1 from '../../img/martial/dashboard-64px.png';
import featureBack2 from '../../img/martial/tracking-64px.png';
import featureBack3 from '../../img/martial/reports-64px.png';
import featureBack4 from '../../img/martial/listing-64px.png';
import {Trans, useTranslation} from 'react-i18next';
import { useHref } from 'react-router-dom';

export default function Features() {
    const href = useHref()
    const { t, i18n } = useTranslation()
  return (
    <>
    <section id='features' className='my-6'>
        <div className='row'>
            <Trans>
                <div className='col-sm-3 d-flex justify-content-between justify-content-sm-start flex-row flex-sm-column '>
                        <h2 className='fw-bolder fs-1 text-myPrimary'>{t("Our")} <br /> {t("Features")}</h2>
                        {href === "/ar" ?
                            <>
                            <img src={featuresAr} className="d-sm-inline-block d-none w-100" alt="features" />
                            <img src={featuresEn} className="d-inline-block d-sm-none w-50" alt="features" />
                            </> :
                            <>
                            <img src={featuresEn} className="d-sm-inline-block d-none w-100" alt="features"  />
                            <img src={featuresAr} className="d-inline-block d-sm-none w-50" alt="features" />
                        </>}
                        {/* <img src={arrow1} className="d-sm-inline-block d-none w-50" alt="features" />
                        <img src={arrow2} className="d-inline-block d-sm-none" alt="features" /> */}
                </div>
                <div className='col-sm-9'>
                    <div className='row g-4'>
                        <div className='col-lg-3 col-md-4 col-6'>
                            <div className='flip-card'>
                                <div className='flip-card-inner'>
                                    <div className='flip-card-front'>
                                        <div className='py-5 image-card'>
                                            <img src={featureFront1} alt="" />
                                        </div>
                                        <div className='pt-5'>
                                        <h2 className='text-myPrimary fs-5 fw-semibold'>{t("Central")} <br /> {t("Dashboard")} </h2>
                                        </div>
                                    </div>
                                    <div className='flip-card-back text-start card-slide-1'>
                                        <div className='pt-5 '>
                                            <img src={featureBack1} alt="" />
                                        </div>
                                        <div className='pt-3'>
                                            <h2 className='fs-5 fw-semibold'>{t("Central")} <br /> {t("Dashboard")}</h2>
                                        </div>
                                        <div className='mt-3'>
                                            <p className='fs-12'>{t("Centra Dahboard Description")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6'>
                            <div className='flip-card'>
                                <div className='flip-card-inner'>
                                    <div className='flip-card-front'>
                                        <div className='py-5 image-card'>
                                            <img src={featureFront2} alt="" />
                                        </div>
                                        <div className='pt-5'>
                                        <h2 className='text-myPrimary fs-5 fw-semibold'>{t("Request, Cost, and Task")} <br /> {t("Tracking")}</h2>
                                        </div>
                                    </div>
                                    <div className='flip-card-back text-start card-slide-4'>
                                        <div className='pt-5 '>
                                            <img src={featureBack2} alt="" />
                                        </div>
                                        <div className='pt-3'>
                                            <h2 className='fs-5 fw-semibold'>{t("Request, Cost, and Task")} <br /> {t("Tracking")}</h2>
                                        </div>
                                        <div className='mt-3'>
                                            <p className='fs-12'>{t("Tracking Description")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6'>
                            <div className='flip-card'>
                                <div className='flip-card-inner'>
                                    <div className='flip-card-front'>
                                        <div className='py-5 image-card'>
                                            <img src={featureFront3} alt="" />
                                        </div>
                                        <div className='pt-5'>
                                        <h2 className='text-myPrimary fs-5 fw-semibold'>{t("Reports")} <br /> {t("and Analysis")}</h2>
                                        </div>
                                    </div>
                                    <div className='flip-card-back text-start card-slide-2'>
                                        <div className='pt-5 '>
                                            <img src={featureBack3} alt="" />
                                        </div>
                                        <div className='pt-3'>
                                            <h2 className='fs-5 fw-semibold'>{t("Reports")} <br /> {t("and Analysis")}</h2>
                                        </div>
                                        <div className='mt-3'>
                                            <p className='fs-12'>{t("Analysis Description")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6'>
                            <div className='flip-card'>
                                <div className='flip-card-inner'>
                                    <div className='flip-card-front'>
                                        <div className='py-5 image-card'>
                                            <img src={featureFront4} alt="" />
                                        </div>
                                        <div className='pt-5'>
                                        <h2 className='text-myPrimary fs-5 fw-semibold'>{t("Private")} <br /> {t("Listing Portal")}</h2>
                                        </div>
                                    </div>
                                    <div className='flip-card-back text-start card-slide-3'>
                                        <div className='pt-5'>
                                            <img src={featureBack4} alt="" />
                                        </div>
                                        <div className='pt-3'>
                                            <h2 className='fs-5 fw-semibold'>{t("Private")} <br /> {t("Listing Portal")}</h2>
                                        </div>
                                        <div className='mt-3'>
                                            <p className='fs-12'>{t("Listing portal Description")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Trans>
        </div>
    </section>
    </>
  )
}
